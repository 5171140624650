import styled from '@emotion/styled';
import { Box } from 'components/Box';
import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Carousel } from 'src/components/Carousel';
import { Flex } from 'src/components/Flex';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import 'swiper/swiper-bundle.css';
import { CustomSliderStyle } from './CustomSliderStyle';

const SlideLink = styled(Box)`
    display: block;
    font-size: 1.4rem;

    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }

    &:after {
        content: ' →';
        font-size: 1.8rem;
        line-height: 1rem;
    }
`;

const ListItem = ({ title, features, link, linkText, slideImage, alt }) => (
    <Box as="li" d="flex" flexDirection="column" h="100%">
        <Flex direction="column" justifyContent="flex-end" flex={1}>
            <Img
                loading="eager"
                alt={alt}
                width="100%"
                height="auto"
                fluid={slideImage}
            />
        </Flex>
        <Flex direction="column" justifyContent="flex-end">
            <Heading kind="h6" mt="spacing-lg">
                {title}
            </Heading>

            <Flex
                justify="space-between"
                align={{
                    _: 'flex-start',
                    xl: 'center',
                }}
                direction={{
                    _: 'column',
                    xl: 'row',
                }}
            >
                <Paragraph
                    isTruncated
                    fontSize="smallBody"
                    color="faintText"
                    mb={{
                        _: 'spacing-lg',
                        xl: '0',
                    }}
                    w={{
                        _: '100%',
                        xl: 'unset',
                    }}
                >
                    {features}
                </Paragraph>

                <SlideLink as="a" href={link} color="boldText">
                    {linkText}
                </SlideLink>
            </Flex>
        </Flex>
    </Box>
);

export function SeeWhatWeBuilt({ heading, items, ...props }: any) {
    const { windowWidth } = useWindowResize();

    let slidesPerView = 1;
    let spaceBetween = 40;
    let slideWidth = 260;

    if (windowWidth > 850) {
        slideWidth = 400;
    }

    if (windowWidth > 1200) {
        slideWidth = 600;
    }

    return (
        <ThemeProvider defaultMode="dark">
            <Section wrap vertical="lg" overflowX="hidden" {...props}>
                <Heading kind="h3">{heading}</Heading>
                <Box display="block" pt="20px">
                    <Carousel
                        slidesPerView={slidesPerView}
                        slideWidth={slideWidth}
                        spaceBetween={spaceBetween}
                        style={{
                            overflow: 'visible',
                            marginLeft: '0',
                        }}
                        slides={items.map((listItem, index) => (
                            <ListItem
                                key={index}
                                title={listItem.title}
                                features={listItem.features}
                                link={listItem.link}
                                linkText={listItem.linkText}
                                slideImage={listItem.image}
                                alt={listItem.alt}
                            />
                        ))}
                    />
                    <CustomSliderStyle />
                </Box>
            </Section>
        </ThemeProvider>
    );
}
