import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null, skip = false) {
    const savedCallback = useRef<() => void | null>();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });
    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (typeof savedCallback?.current !== 'undefined' && savedCallback) {
                savedCallback.current();
            }
        }

        if (!skip && delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => null;
    }, [delay]);
}