import { Layout } from 'components/Layout';
import { MethodsCTA } from 'components/Methods/MethodsCTA';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { Heading } from 'src/components/Heading';
import { MediaSection } from 'src/components/MediaSection';
import { BuildCards } from 'src/components/Methods/build-method/BuildCards';
import { SeeWhatWeBuilt } from 'src/components/Methods/build-method/SeeWhatWeBuilt';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';

const subnav = [
    {
        id: 'capabilities',
        label: 'Capabilities',
    },
    {
        id: 'whatwevebuilt',
        label: "What We've Built",
    },
    {
        id: 'project-management',
        label: 'Project Management',
    },
    {
        id: 'technology',
        label: 'Technology',
    },
];

export default function build({ data }) {
    const flairColor = '#5BCD9F';
    return (
        <Layout
            pageTitleFull="Build your online community platform with Audentio"
            siteDescription="We build the technology your digital platform needs with a team of developers, designers, and strategists."
            contactForm
            methodSubNavIcon="/methods/build.svg"
            methodSubNavColor={flairColor}
            subnavTitle="Build"
            subnav={subnav}
        >
            <MethodsLandingHero
                title="With a defined roadmap, we build the technology you need"
                video="/videos/methods/build-method/hero.mp4"
                videoPoster="/videos/methods/build-method/hero-poster.png"
                videoId="methods-build-hero"
                subtitle="With a strategic plan in hand, we begin to build what you're passionate about and execute on the deliverables that will set your online community up for success."
            />

            <Section id={subnav[0].id} wrap vertical="lg" overflow={['hidden', 'visible']}>
                <Box maxWidth="600px" m="auto">
                    <Heading
                        kind="h3"
                        as="h2"
                        mb="spacing-lg"
                        textAlign="center"
                    >
                        We execute on your community’s needs. No detail goes
                        unnoticed.
                    </Heading>

                    <Paragraph color="bodyText" textAlign="center">
                        We spend on average three to six months building your
                        online community platform by introducing new features,
                        fine-tuning strategies based on metrics, and more.
                    </Paragraph>
                </Box>

                <Box
                    w="100%"
                    maxWidth={992}
                    m="auto"
                    pt="spacing-xl"
                    //overflow={['hidden', 'visible']}
                >
                    <BuildCards />
                </Box>
            </Section>

            <MethodsCTA
                title="Save your time. Get higher returns. Have the community you deserve."
                flairColor={flairColor}
            />

            <SeeWhatWeBuilt
                heading="See what we’ve built"
                id={subnav[1].id}
                items={[
                    {
                        title: 'paradox interactive',
                        features: 'design, data migration, metrics, strategy',
                        link: 'https://www.audent.io/case-study/gaming/full',
                        linkText: 'see gaming industry',
                        image: data.paradoxindustry.childImageSharp.fluid,
                        alt: 'Community design and migration for Paradox Interactive, a video game publishing studio that focused on grand strategy games.',
                    },
                    {
                        title: 'xda developers',
                        features: 'design, data migration, app',
                        link: 'https://www.audent.io/case-study/tech/full',
                        linkText: 'see tech industry',
                        image: data.xdaindustry.childImageSharp.fluid,
                        alt: 'Community design and migration from vBulletin for XDA Developers, a mobile software development community.',
                    },
                    {
                        title: 'synyster gates school',
                        features:
                            'design, data migration, management, strategy',
                        link: 'https://www.audent.io/case-study/lifestyle/full',
                        linkText: 'see lifestyle industry',
                        image: data.synindustry.childImageSharp.fluid,
                        alt: 'Community design, migration and strategy for Synner, a guitar learning community started by Syngates Gates from Avenged Sevenfold.',
                    },
                    {
                        title: 'student doctor network',
                        features: 'design, data migration, management',
                        link:
                            'https://www.audent.io/case-study/government/full',
                        linkText: 'see government & non-profit industry',
                        image: data.sdnindustry.childImageSharp.fluid,
                        alt: 'Community design and migration for Student Doctor Network, a non-profit organization helping medical students from all fields.',
                    },
                    {
                        title: 'cyclonefanatic',
                        features: 'design, data migration',
                        link: 'https://www.audent.io/case-study/sports/full',
                        linkText: 'see sports industry',
                        image: data.cycloneindustry.childImageSharp.fluid,
                        alt: 'Community design and migration for Cyclone Fanatic, a sports news and community dedicated to Iowa State college sports.',
                    },
                    {
                        title: 'bdoutdoors',
                        features: 'design, data migration',
                        link: 'https://www.audent.io/case-study/outdoors/full',
                        linkText: 'see outdoors industry',
                        image: data.bdoindustry.childImageSharp.fluid,
                        alt: 'Community design and XenForo upgrade for BDoutdoors, a fishing and boating community for enthusiasts.',
                    },
                ]}
            />

            <MediaSection
                id={subnav[2].id}
                flipped
                // videoPoster="/videos/vbmigrationposter.jpg"
                video="/videos/methods/build-method/gantt.mp4"
                videoId="build-gantt"
                title="Get an accurate overview of how your project is developing"
                backgroundColor="secondaryBackground"
            >
                <Paragraph>
                    We’re transparent about our schedule, workflow, and progress
                    during the entire project so you never worry.
                </Paragraph>
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Dedicated project manager on hand',
                        },
                        {
                            title:
                                'Organized into sprints and clear deliverables',
                        },
                        {
                            title:
                                'Routine meetings to accurately track progress',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </MediaSection>

            <Section vertical="md" pt={[0, 0, 0]} wrap bg="secondaryBackground">
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>

            <MediaSection
                id={subnav[3].id}
                flipped
                video="/videos/methods/build-method/dev.mp4"
                videoId="build-dev"
                title="Products that improve functionality, stay reliable, and evolve with time."
                backgroundColor="primaryBackground"
            >
                Our development process is always backed up by the latest
                technologies. We are technology experts with the belief that we
                should never stop learning and therefore there is no limit to
                what we can build.
            </MediaSection>
        </Layout>
    );
}

export const query = graphql`
    query buildQuery {
        paradoxindustry: file(
            relativePath: { eq: "images/build-method/paradox.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        xdaindustry: file(
            relativePath: { eq: "images/build-method/xda.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        synindustry: file(
            relativePath: { eq: "images/build-method/syn.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sdnindustry: file(
            relativePath: { eq: "images/build-method/sdn.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cycloneindustry: file(
            relativePath: { eq: "images/build-method/cyclone.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bdoindustry: file(
            relativePath: { eq: "images/build-method/bdo.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
