import { graphql, useStaticQuery } from 'gatsby';

export const useBuildCardData = () => {
    const data = useStaticQuery(buildCardQuery);

    return buildCardList.map(item => ({
        ...item,
        icon: data && data[item.id]?.childImageSharp?.fixed,
    }));
};

const buildCardList = [
    {
        title: 'plan and build subscription systems',
        id: 'subscriptionsystems',
    },
    {
        title: 'unique badge designs for user achievements',
        id: 'badgedesigns',
    },
    {
        title: 'send newsletters to subscribers',
        id: 'newsletter',
    },
    {
        title: 'data migrations and go-live support',
        id: 'datamigration',
    },
    {
        title: 'run contests and giveaways',
        id: 'contests',
    },
    {
        title: 'integrate your e-commerce shop',
        id: 'shopify',
    },
    {
        title: 'record tutorial video series for new members',
        id: 'tutorial',
    },
    {
        title: 'plan gaming events and help with streaming',
        id: 'gamingevent',
    },
    {
        title: 'design care packages for members',
        id: 'carepackage',
    },
    {
        title: 'design and specify brand guidelines',
        id: 'brandguidelines',
    },
    {
        title: 'data flow and software diagram',
        id: 'dataflow',
    },
    {
        title: 'tailored reports for critical KPIs',
        id: 'reports',
    },
    {
        title: 'design a sponsor pitch deck',
        id: 'sponsordeck',
    },
    {
        title: 'plan user titles relevant to community',
        id: 'usertitles',
    },
    {
        title: 'coordinate certification and training programs',
        id: 'certification',
    },
    {
        title: 'audit performance with recommendations',
        id: 'audit',
    },
    {
        title: 'reorganize node structure',
        id: 'nodestructure',
    },
    {
        title: 'implement a donation campaign',
        id: 'donation',
    },
    {
        title: 'server and speed optimizations',
        id: 'server',
    },
    {
        title: 'implement leaderboard and award winners',
        id: 'leaderboard',
    },
];

const buildCardQuery = graphql`
    query buildCardsQuery {
        audit: file(
            relativePath: { eq: "images/build-method/icons/audit.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        badgedesigns: file(
            relativePath: { eq: "images/build-method/icons/badgedesigns.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        brandguidelines: file(
            relativePath: {
                eq: "images/build-method/icons/brandguidelines.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        carepackage: file(
            relativePath: { eq: "images/build-method/icons/carepackage.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        certification: file(
            relativePath: { eq: "images/build-method/icons/certification.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        contests: file(
            relativePath: { eq: "images/build-method/icons/contests.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        dataflow: file(
            relativePath: { eq: "images/build-method/icons/dataflow.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        datamigration: file(
            relativePath: { eq: "images/build-method/icons/datamigration.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        donation: file(
            relativePath: { eq: "images/build-method/icons/donation.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        gamingevent: file(
            relativePath: { eq: "images/build-method/icons/gamingevent.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        leaderboard: file(
            relativePath: { eq: "images/build-method/icons/leaderboard.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        newsletter: file(
            relativePath: { eq: "images/build-method/icons/newsletter.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        nodestructure: file(
            relativePath: { eq: "images/build-method/icons/nodestructure.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        reports: file(
            relativePath: { eq: "images/build-method/icons/reports.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        server: file(
            relativePath: { eq: "images/build-method/icons/server.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        shopify: file(
            relativePath: { eq: "images/build-method/icons/shopify.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        sponsordeck: file(
            relativePath: { eq: "images/build-method/icons/sponsordeck.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        subscriptionsystems: file(
            relativePath: {
                eq: "images/build-method/icons/subscriptionsystems.png"
            }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        tutorial: file(
            relativePath: { eq: "images/build-method/icons/tutorial.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        usertitles: file(
            relativePath: { eq: "images/build-method/icons/usertitles.png" }
        ) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;
