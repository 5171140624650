import { css, Global } from '@emotion/core';
import React from 'react';
import { ThemeType } from '../theme';

export function CustomSliderStyle() {
    return (
        <Global
            styles={(theme: ThemeType) => css`
                .swiper-slide {
                    height: unset;
                }
            `}
        />
    );
}
