import { AnimatePresence, motion } from 'framer-motion';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { AnimatedContainer } from 'src/components/AnimatedContainer';
import { Box } from 'src/components/Box';
import { Grid } from 'src/components/Grid';
import { Heading } from 'src/components/Heading';
import { useTheme } from 'src/components/ThemeProvider';
import { useInterval } from 'src/helpers/useInterval';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { useBuildCardData } from './useBuildCardData';

const BuildCardContainer = ({ children, isActive }) => {
    return (
        <Box
            h="100%"
            w="100%"
            bg="primaryBackground"
            border="1px"
            borderColor="border"
            shadow={isActive ? 'raised' : 'card'}
            p="spacing-lg"
            boxSizing="border-box"
        >
            {children}
        </Box>
    );
};

const BuildCardContent = ({ title, icon }) => {
    return (
        <>
            <Box w={[45, null, 55]} h={[45, null, 55]} mb="spacing-sm">
                {icon && (
                    <Img
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        loading="eager"
                        fadeIn={false}
                        fixed={icon}
                    />
                )}
            </Box>

            <Heading kind="h6" pt="spacing" mb={0}>
                {title}
            </Heading>
        </>
    );
};

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export const BuildCards = props => {
    const buildCardList = useBuildCardData();
    const { breakpoint } = useTheme();
    const { windowWidth } = useWindowResize();
    let cardsVisible = 6;

    if (windowWidth > breakpoint.stripped.md) {
        cardsVisible = 9;
    }

    if (windowWidth > breakpoint.stripped.xl) {
        cardsVisible = 12;
    }

    const [visibleList, setVisibleList] = useState(
        buildCardList.slice(0, cardsVisible)
    );
    const [hiddenList, setHiddenList] = useState(
        buildCardList.slice(cardsVisible, buildCardList?.length)
    );

    const [selected, setSelected] = useState(null);

    const updateSelected = async () => {
        const randomIndex = randomNumber(0, cardsVisible - 1);
        let selectedItem = visibleList[randomIndex];
        if (selectedItem?.id === selected?.id) {
            const newSelectedItem =
                randomIndex > 0
                    ? visibleList[randomIndex - 1]
                    : visibleList[randomIndex + 1];
            selectedItem = newSelectedItem;
        }

        setSelected(selectedItem);
    };

    useInterval(() => {
        updateSelected();
    }, 3000);

    const updateVisibleList = async (index, replaceItem) => {
        setVisibleList(prev => {
            const prevList = [...prev];

            prevList.splice(index, 1, replaceItem);

            return prevList;
        });
    };

    const updateList = (index, selectedItem) => {
        setHiddenList(prevInvis => {
            updateVisibleList(index, prevInvis[0]);

            const newInvisList = [...prevInvis];
            newInvisList.push(selectedItem);
            newInvisList.shift();
            return newInvisList;
        });
    };

    const smallMobile = windowWidth && windowWidth > 1 && windowWidth < 350;
    const gridColumns = smallMobile ? 1 : [2, null, 3, null, 4];

    return (
        <Grid columns={gridColumns}>
            {visibleList.map(({ title, id, icon }, index) => {
                const isSelected = id === selected?.id;

                return (
                    <AnimatedContainer
                        key={`container_${id}`}
                        isAnimated={isSelected}
                        allowMobileAnimation
                        offset={{
                            initial: {
                                scale: 1,
                            },
                            final: {
                                scale: smallMobile ? 1.05 : 1.2,
                            },
                        }}
                        transitionProps={{
                            delay: 0,
                        }}
                    >
                        <BuildCardContainer isActive={isSelected}>
                            <AnimatePresence>
                                {isSelected ? (
                                    <motion.div
                                        // key={`card_${id}`}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            duration: 0.75,
                                        }}
                                    >
                                        <SelectedCard
                                            key={`card_${id}`}
                                            title={title}
                                            icon={icon}
                                            id={id}
                                            index={index}
                                            updateList={updateList}
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            duration: 0.75,
                                        }}
                                    >
                                        <BuildCardContent
                                            key={`card_${id}`}
                                            title={title}
                                            icon={icon}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </BuildCardContainer>
                    </AnimatedContainer>
                );
            })}
        </Grid>
    );
};

const SelectedCard = ({ index, updateList, title, icon, id }) => {
    useEffect(() => {
        return () => updateList(index, { title, icon, id });
    }, []);

    return <BuildCardContent title={title} icon={icon} />;
};
